import React from 'react'
import Navbar from './homepagepartials/navbar'


interface Props {
}

function Landingpage(props: Props) {
    const {} = props

    return (
        <>
            <Navbar/>
        </>
    )
}

export default Landingpage;
